/**
 * Transfroms an image url to a base64 encoded string
 */
export async function fromUrlToBase64(url: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('File yielded no result'));
        }
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

// extract base 64 from the file
export async function extractBase64FromFile(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      if (reader.result) {
        resolve(reader.result as string);
      } else {
        reject(new Error('File yielded no result'));
      }
    };
    reader.readAsDataURL(file);
  });
}

// extract base 64 from the file and remove the header from the base64 string
export async function extractBase64WithoutHeader(file: File): Promise<string> {
  const base64 = await extractBase64FromFile(file);
  return base64.split(',')[1] || '';
}

export async function urlToFile(url: string, filename: string): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, {
    type: 'image/jpeg',
  });
}
